import { addRule } from 'redux-ruleset'
import historyState from 'utils/historyState'

addRule({
  id: 'listing/HISTORY_STATE_HYDRATION',
  target: 'PARTIAL_STATE_UPDATES',
  output: 'PARTIAL_STATE_UPDATES',
  position: 'INSTEAD',
  condition: action => {
    const row = action.payload.find(row => row.path[0] === 'products')
    if(!row) return false
    const identifier = row.path[1]
    return historyState.has(identifier)
  },
  consequence: action => {
    const row = action.payload.find(row => row.path[0] === 'products')
    if(!row) return
    const identifier = row.path[1]
    const state = historyState.load(identifier)
    return {
      ...action,
      payload: action.payload.map(item => item !== row ? item : {...item, state})
    }
  }
})

addRule({
  id: 'listing/SAVE_TO_HISTORY',
   target: ['products/FETCH_SUCCESS'],
   output: '#url-hash',
   consequence: (action, {getState}) => {
    const identifier = action.meta.identifier
    const state = getState().products[identifier]
    historyState.save(identifier, state)
   }
})