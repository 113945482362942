
function save (key:string, value:any) {
  if(typeof window === 'undefined') return
  const prevState = history.state ?? {}
  history.replaceState({...prevState, [key]: value}, '', location.href)
}

function has(key:string) {
  if(typeof window === 'undefined') return false
  const state = history.state ?? {}
  return Boolean(state[key])
}

function load(key:string) {
  if(typeof window === 'undefined') return null
  const state = history.state ?? {}
  return state[key] ?? null
}

const historyState = {save, load, has}

export default historyState