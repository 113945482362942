import { addRule, dispatchEvent } from 'redux-ruleset'
import config from 'config'

// modules
import * as products from 'modules/products'
import * as posthog from 'modules/posthog'
import * as tracking from 'modules/tracking'

// widgets
import * as ProductWidget from 'theme/molecules/ProductWidget/actions'
import * as Sidebar from 'theme/_core/Sidebar/actions'

const pushToPosthog = (event: any) => dispatchEvent(posthog.sendCapture(event))

if (config.features.posthog) {
  addRule({
    id: 'posthogtracking/LIST_CLICKOUT',
    output: posthog.SEND_CAPTURE,
    target: ProductWidget.CLICK,
    condition: (action) => !action.payload.groupedId,
    consequence: (action, { effect }) => {
      effect(() =>
        pushToPosthog({
          eventName: 'merchant_clickout',
          eventProperties: {
            merchant: action.payload.merchantName,
            brand: action.payload.productManufacturerBrand,
            price: action.payload.productPrice,
            name: action.payload.productName,
            product_id: action.payload.objectID,
          }
        })
      )
    },
  })

  addRule({
    id: 'posthogtracking/SIDEBAR_CLICK',
    output: tracking.SEND_EVENT,
    target: Sidebar.CLICK_ITEM,
    consequence: (action, { effect }) => {
      effect(() =>
        pushToPosthog({
          eventName: 'main_navigation',
          eventProperties: {
            menu_item: action.payload
          }
        })
      )
    },
  })

  addRule({
    id: 'posthogtracking/filter/TOGGLE_FILTER',
    output: tracking.SEND_EVENT,
    target: products.TOGGLE_FILTER_OPTION,
    position: 'BEFORE',
    consequence: (action, { getState, effect }) => {
      const state = getState()
      const { identifier, filterKey } = action.meta
      const values = products.getFilterValue(state.products, identifier, filterKey)
      const on = !values.includes(action.payload)
      effect(() =>
        pushToPosthog({
          eventName: 'filter',
          eventProperties: {
            filter_name: filterKey,
            filter_value: action.payload + (on ? '_on' : '_off')
          }
        })
      )
    },
  })

  addRule({
    id: 'posthogtracking/SCROLL_PRODUCT_INTO_VIEW',
    output: tracking.SEND_EVENT,
    target: ProductWidget.SCROLL_INTO_VIEW,
    consequence: (action, { effect }) => {
      effect(() =>
        pushToPosthog({
          eventName: 'impression_view',
          eventProperties: {
            merchant: action.payload.merchantName,
            brand: action.payload.productManufacturerBrand,
            price: action.payload.productPrice,
            name: action.payload.productName,
            product_id: action.payload.objectID,
            category: window.location.pathname,
            list: action.meta.listName,
            position: action.meta.index + 1,
          }
        })
      )
    },
  })

  addRule({
    id: 'posthogtracking/PRODUCT_CLICK',
    output: tracking.SEND_EVENT,
    target: ProductWidget.CLICK,
    consequence: (action, { effect }) => {
      effect(() =>
        pushToPosthog({
          eventName: 'impression_click',
          eventProperties: {
            merchant: action.payload.merchantName,
            brand: action.payload.productManufacturerBrand,
            price: action.payload.productPrice,
            name: action.payload.productName,
            product_id: action.payload.objectID,
            category: window.location.pathname,
            list: action.meta.listName,
            position: action.meta.index + 1,
          }
        })
      )
    },
  })

}