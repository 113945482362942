import { addRule } from 'redux-ruleset'


// let history:{scrollY:number,url:string}[] = []
const history = new Map<string, { scrollY: number, url: string }>()

addRule({
  id: 'feature/SHOW_PDP',
  target: 'navigation/LOCATION_CHANGE',
  output: '#update-body-class',
  condition: (action) => (action.payload.search + action.payload.hash).includes('product-id=') && !document.body.classList.contains('show-pdp'),
  consequence: () => {
    const key = window.history.state?.key
    if (key) {
      history.set(key, { 
        scrollY: window.scrollY, 
        url: window.location.pathname 
      })
    }
    document.body.classList.add('show-pdp')
    // Remove the window.scrollTo(0, 0) to keep scroll position
  }
})

addRule({
  id: 'feature/HIDE_PDP',
  target: 'navigation/LOCATION_CHANGE',
  output: '#update-body-class',
  condition: (action) => !(action.payload.search + action.payload.hash).includes('product-id=') && document.body.classList.contains('show-pdp'),
  consequence: () => {
    document.body.classList.remove('show-pdp')
    const key = window.history.state?.key ?? ''
    const last = history.get(key)
    if (last && window.location.pathname === last.url) {
      history.delete(key)
      document.body.classList.add('pdp-pending-unmount')
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          document.body.classList.remove('pdp-pending-unmount')
          window.scrollTo(0, last.scrollY)
        })
      })
    }
  }
})