import React from 'react'

const Context = React.createContext<boolean>(false)

export const IsCategoryRouteProvider = ({ children, isCategoryRoute = true }: { children: React.ReactNode, isCategoryRoute: boolean }) => {
    return (
        <Context.Provider value={isCategoryRoute}>
            {children}
        </Context.Provider>
    )
}

export const useIsCategoryRoute = () => {
    return React.useContext(Context)
}
