import { addRule } from 'redux-ruleset'
import * as m from 'modules/products'
import { LOCATION_CHANGE } from 'modules/navigation'

/**
 * Given the user is on a category page
 * And navigates to another page
 * When the current page !== 0
 * Then we reset the page to 0
 */
addRule({
    id: 'feature/RESET_CATEGORY_PAGE',
    target: LOCATION_CHANGE,
    output: '#url-hash',
    condition: (action) => {
        if (typeof window === 'undefined') return false
        const prevLocation = action.meta?.prevLocation
        const nextLocation = action.payload
        if (!prevLocation || !nextLocation) return false
        const isShopPage = nextLocation.pathname.includes('/shop/')
        const pathChanged = prevLocation.pathname !== nextLocation.pathname
        const result = isShopPage && pathChanged
        return result
    },
    consequence: (action) => {
        const { pathname, search, hash } = action.payload
        // Deserialize the current hash
        const filterValues: any = m.utils.deserialize(hash) // Treat as any to allow deleting 'p'
        // Serialize the modified values back to a hash string
        // 'skipCategory: true''skipPage: true' as we are on a shop/category page
        const newHash = m.utils.serialize(filterValues, { skipCategory: true, skipPage: true })
        window.history.replaceState(null, '', pathname + search + newHash)
    },
})

