// @flow
import config from 'config'

/**
 * Returns a deeplink for the given product in the format:
 * http://…shop/bekleidung/categoryLevel1/categoryLevel3…/?product-id=OBJECT_ID
 * 
 * @param p Product object
 * @returns The deeplink URL string for the product with its category path
 */
export function pdpLeafUrl(p: any, relative: boolean = false) {
    if (!p) {
        return ''
    }

    // Get the category path for the product
    const categoryPath: string[] = p?.wunderCategories ?? []
    if (categoryPath.length === 0) {
        return ''
    }

    // Create a dictionary mapping category paths to URL keys
    const catDict: Record<string, string> = {}
    for (const key in config.categories) {
        const category = config.categories[key]
        catDict[category.categoryPath] = category.urlKey
    }

    // Default category URL path
    const defaultPath = '/shop/bekleidung'

    // Find the deepest available category path that has a URL key
    let categoryUrlPath = defaultPath

    // Try the full path first, then progressively shorter paths
    for (let i = categoryPath.length; i > 0; i--) {
        const partialPath = categoryPath.slice(0, i)
        const pathKey = partialPath.join(' > ')

        if (catDict[pathKey]) {
            // Extract category parts after /shop/bekleidung/
            categoryUrlPath = extractCategoryPath(catDict[pathKey], defaultPath)
            break
        }
    }

    // Use window.location.origin in development mode, otherwise use config.siteUrl
    const baseUrl = process.env.NODE_ENV === 'development' && typeof window !== 'undefined'
        ? window.location.origin
        : config.siteUrl + config.prefix

    // Build the final URL with product ID
    return `${relative ? '' : baseUrl}${categoryUrlPath}/?product-id=${p.objectID}`
}

/**
 * Helper function to extract the relevant category path from a full URL
 * Removes leading slash, 'shop', and 'bekleidung' if present
 * 
 * @param fullUrl The full category URL
 * @param defaultPath The default path to prepend
 * @returns The processed category URL path
 */
function extractCategoryPath(fullUrl: string, defaultPath: string): string {
    const categoryParts = fullUrl.split('/')

    // Skip the first part if it's empty (from leading slash)
    const startIndex = categoryParts[0] === '' ? 1 : 0

    // Skip 'shop' and 'bekleidung' if they're in the path
    let skipCount = 0
    if (categoryParts[startIndex] === 'shop') skipCount++
    if (categoryParts[startIndex + skipCount] === 'bekleidung') skipCount++

    // Build the category path
    let result = defaultPath
    for (let i = startIndex + skipCount; i < categoryParts.length; i++) {
        if (categoryParts[i]) {
            result += '/' + categoryParts[i]
        }
    }

    return result
}

export function clickoutUrl(p: any) {
    if (!p) {
        return ''
    }
    const { merchantClickout } = config.apiEndpoints
    const baseUrl = merchantClickout + '/' + p.objectID

    const reqParams = new URLSearchParams()
    if (typeof window !== 'undefined') {
        reqParams.set('branch', window.abbranch || 'master')
        if (
            config.features.posthog
            && config.features.posthogDistinctIdClickoutUrlParam
            && window.posthog) {
            const distinctId = window.posthog.get_distinct_id()
            reqParams.set('phdid', distinctId)
        }
    }
    const queryString = reqParams.toString()
    const clickoutUrlWithParams = queryString ? `${baseUrl}?${queryString}` : baseUrl
    return clickoutUrlWithParams
}

export function imageUrl(p: any) {
    const { imageMediumURL, merchantName } = p
    const c = config.modules.cloudinary
    const cloudinaryFetchUrl = `${c.imageFetchUrl}
        ${c.imageTransformations.productwidget['LAPTOP_XL']}
        ,dpr_2/`

    // backlisted merchant, use merchant image url, assure (force!) httpS/ SSL
    if (c.blacklistedMerchants.indexOf(merchantName) !== -1) {
        return imageMediumURL
    }

    if (imageMediumURL.indexOf('productserve') !== -1) {
        const url = /url=(.*)/.exec(imageMediumURL)
        if (url) {
            const imgSrc = 'https://' + url[1]
            return `${cloudinaryFetchUrl}/${imgSrc}`
        }
        return imageMediumURL
    }

    return `${cloudinaryFetchUrl}/${encodeURIComponent(imageMediumURL)}`
}

export function productNameSanitizer(productName: string) {
    return productName.split(',')[0]
}
