// @flow
import * as t from '../types'
import config from 'config'

export const defaultFilterValues: t.FilterValues = {
  page: 0,
  query: '',
  tags: [],
  color: [],
  brand: [],
  size: [],
  shop: [],
  category: '',
  price: null,
  context: '',
  analyticTags: [],
  objectIds: [],
  wunderCategories: [],
  index: config.indexes.products,
  sorting: config.sortingAttributes.default,
  dynamicFilters: {},
}

export const areFilterValuesEqual = (f1: t.FilterValues, f2: t.FilterValues): boolean => {
  for (const key in f1) {
    if(key === 'analyticTags') {null}
    else if (key === 'dynamicFilters') {
      if (!areFilterValuesEqual(f1[key] as any, f2[key] as any)) return false
    } else if (Array.isArray(f1[key])) {
      if (!Array.isArray(f2[key])) return false
      if (f1[key].length !== f2[key].length) return false
      for (let i = 0; i < f1[key].length; i++) {
        if (f1[key][i] !== f2[key][i]) return false
      }
    } else if (Array.isArray(f2[key])) {
      return false
    } else if (f1[key] !== f2[key]) {
      return false
    }
  }
  return true
}

const encode = (s: string) => encodeURIComponent(s)
const decode = (s: string) => decodeURIComponent(s)

export const mergeQueryString = (filterValues: t.FilterValues, hash: string): t.FilterValues => {
  const f2 = deserialize(hash)
  return mergeFilterValues(filterValues, f2)
}

export const serialize = (
  filterValues: t.FilterValues,
  opt: {
    skipPage?: boolean
    skipCategory?: boolean
    skipTags?: string[]
  } = {}
): string => {
  let hash = '#q=' + encode(filterValues.query)

  if(filterValues.page && !opt.skipPage){
    hash += '&p=' + filterValues.page
  }

  if (filterValues.index !== config.indexes.products) {
    hash += `&idx=` + encode(filterValues.index)
  }

  if (filterValues.category && !opt.skipCategory) {
    hash += '&hFR[categories][0]=' + encode(filterValues.category)
  }

  filterValues.tags.forEach((tag, i) => {
    if (opt.skipTags && opt.skipTags.includes(tag)) return
    hash += `&tR[${i}]=` + encode(tag)
  })

  filterValues.size.forEach((size, i) => {
    hash += `&dFR[wunderSizes][${i}]=` + encode(size)
  })

  filterValues.brand.forEach((brand, i) => {
    hash += `&dFR[productManufacturerBrand][${i}]=` + encode(brand)
  })

  filterValues.color.forEach((color, i) => {
    hash += `&dFR[filterColor][${i}]=` + encode(color)
  })

  filterValues.shop.forEach((shop, i) => {
    hash += `&dFR[merchantName][${i}]=` + encode(shop)
  })

  filterValues.wunderCategories.forEach((cat, i) => {
    hash += `&dFR[wunderCategories][${i}]=` + encode(cat)
  })

  if (filterValues.price) {
    hash += `&nR[productPrice][<=][0]=${filterValues.price[0]}`
    hash += `&nR[productPrice][>=][0]=${filterValues.price[1]}`
  }

  Object.keys(filterValues.dynamicFilters).forEach((key) => {
    filterValues.dynamicFilters[key].forEach((val, i) => {
      hash += `&dFR[${encode(key)}][${i}]=` + encode(val)
    })
  })

  if (hash === '#q=') return ''

  return hash
}

export const deserialize = (hash: string): t.FilterValues => {
  if (!hash) return defaultFilterValues
  hash = decodeURIComponent(hash.replace(/&/g, '```'))
    .replace(/</g, '%3C') // price
    .replace(/>/g, '%3E') // price
    .replace(/ %3E /g, ' > ') // categories

  // query
  const queryMatch = hash.match(/#q=([^(```)]*)/)
  const query = queryMatch ? decode(queryMatch[1]) : defaultFilterValues.query

  // // page
  const pageMatch = hash.match(/```p=([^(```)]*)/)
  const page = pageMatch ? parseInt(pageMatch[1]) : defaultFilterValues.page

  // index
  const indexMatch = hash.match(/```idx=([^(```)]*)/)
  const index = indexMatch ? decode(indexMatch[1]) : defaultFilterValues.index

  // category
  const categoryMatch = hash.match(/```hFR\[categories\]\[0\]=([^(```)]*)/)
  const category = categoryMatch ? decode(categoryMatch[1]) : defaultFilterValues.category

  // tags
  const tagMatch = hash.match(/```tR\[[0-9]+\]=([^(```)]*)/g)
  const tags = tagMatch ? tagMatch.map((t) => decode(t.split('=')[1])) : defaultFilterValues.tags

  // color
  const colorMatch = hash.match(/```dFR\[filterColor\]\[[0-9]+\]=[^(```)]*/g)
  const color = colorMatch
    ? colorMatch.map((f) => decode(f.split('=')[1]))
    : defaultFilterValues.color
  if (colorMatch) colorMatch.map((s) => (hash = hash.replace(s, '')))

  // shop
  const shopMatch = hash.match(/```dFR\[merchantName\]\[[0-9]+\]=([^(```)]*)/g)
  const shop = shopMatch ? shopMatch.map((f) => decode(f.split('=')[1])) : defaultFilterValues.shop
  if (shopMatch) shopMatch.map((s) => (hash = hash.replace(s, '')))

  // brand
  const brandMatch = hash.match(/```dFR\[productManufacturerBrand\]\[[0-9]+\]=([^(```)]*)/g)
  const brand = brandMatch
    ? brandMatch.map((f) => decode(f.split('=')[1]))
    : defaultFilterValues.brand
  if (brandMatch) brandMatch.map((s) => (hash = hash.replace(s, '')))

  // admin categories
  const adminCatMatch = hash.match(/```dFR\[wunderCategories\]\[[0-9]+\]=([^(```)]*)/g)
  const adminCat = adminCatMatch
    ? adminCatMatch.map((f) => decode(f.split('=')[1]))
    : defaultFilterValues.wunderCategories
  if (adminCatMatch) adminCatMatch.map((s) => (hash = hash.replace(s, '')))

  // size
  const sizeMatch = hash.match(/```dFR\[wunderSizes\]\[[0-9]+\]=([^(```)]*)/g)
  const size = sizeMatch ? sizeMatch.map((f) => decode(f.split('=')[1])) : defaultFilterValues.size
  if (sizeMatch) sizeMatch.map((s) => (hash = hash.replace(s, '')))

  // price
  const priceSmallerMatch = hash.match(/```nR\[productPrice\]\[%3C=\]\[0\]=([^(```)]*)/)
  const priceBiggerMatch = hash.match(/```nR\[productPrice\]\[%3E=\]\[0\]=([^(```)]*)/)
  let price: any = null
  if (priceSmallerMatch && priceBiggerMatch) {
    price = [parseInt(priceSmallerMatch[1]), parseInt(priceBiggerMatch[1])]
  }

  // dynamicFilters
  const dynamicFilterMatch = hash.match(/```dFR\[[^\]]*\]\[[0-9]+\]=([^(```)]*)/g)
  const dynamicFilters: Record<string, t.FilterOption[]> = {}
  if (dynamicFilterMatch)
    dynamicFilterMatch.forEach((s) => {
      const match = s.match(/```dFR\[([^\]]*)\]\[[0-9]+\]=([^(```)]*)/)
      if (!match) return
      const [, path, label] = match
      if (!dynamicFilters[path]) dynamicFilters[path] = []
      dynamicFilters[path].push(label)
    })

  return {
    ...defaultFilterValues,
    query,
    page,
    category,
    tags,
    color,
    shop,
    brand,
    size,
    price,
    index,
    wunderCategories: adminCat,
    dynamicFilters,
  }
}

// eslint-disable-next-line no-undef
export const createFilterValues = (shape: Partial<t.FilterValues>): t.FilterValues => ({
  ...defaultFilterValues,
  ...shape,
})

export const mergeFilterValues = (f1: t.FilterValues, f2: t.FilterValues): t.FilterValues => {
  const merged: any = {}
  for (const key in f1) {
    if (typeof f1[key] === 'string' || typeof f1[key] === 'number') {
      merged[key] = f1[key] || f2[key]
    } else if (f1[key] === null) {
      merged[key] = f2[key]
    } else if (Array.isArray(f1[key])) {
      if (f1[key].length) merged[key] = f1[key]
      else merged[key] = f2[key]
    }
  }
  return merged
}
